import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root.reducer';

const sagaMiddleware = createSagaMiddleware();
const Middleware = [sagaMiddleware];

// Middleware.push(logger);

if (process.env.NODE_ENV === 'development') {
    Middleware.push(logger);

}

export const store = createStore(rootReducer, applyMiddleware(...Middleware));

export const persistor = persistStore(store);

// export default { store, persistor };
const exportStore = { store, persistor };

export default exportStore;