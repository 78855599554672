import superAdminCompanyActionTypes from './superAdminCompany.type';

const INITIAL_STATE = null;

const superAdminCompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case superAdminCompanyActionTypes.SET_SUPER_ADMIN_COMPANY:
      return action.payload;
    default:
      return state;
  }
};

export default superAdminCompanyReducer;
