import ThemeActionTypes from "./themeMode.type";

const INITIAL_STATE = "light";

const themeModeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemeActionTypes.SET_THEME_MODE:
      return action.payload;
    default:
      return state;
  }
};

export default themeModeReducer;
