import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import openSocket from 'socket.io-client';

import { selectToken } from '../src/redux/token/token.selector';
import { selectCurrentUser } from '../src/redux/user/user.selector';
import { selectCompany } from './redux/company/company.selector';
import { setTheme } from '../src/redux/theme/theme.action';
import { setActiveClass } from '../src/redux/sidebarActive/sidebarActive.action';
import { setCurrentUser } from '../src/redux/user/user.action';

import { getUser } from './services/authentication.service';
// import { getSetting } from "./services/setting.service";

import './style.scss';
import './black_theme.scss';
import './variable.scss';
import './App.css';

export let socket;

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// main Layout
const LayOut = Loadable({
  loader: () => import('./default-layout/index'),
  loading,
});

const Login = Loadable({
  loader: () => import('./components/authentication/login'),
  loading,
});

const Forgot = Loadable({
  loader: () => import('./components/authentication/forgetPassword'),
  loading,
});

const DeliveryPayment = Loadable({
  loader: () => import('./components/deliveryPayment/index'),
  loading,
});

const CompanyRegister = Loadable({
  loader: () => import('./components/companyRegister/companyRegister'),
  loading,
});

const CompanySubscription = Loadable({
  loader: () => import('./components/companySubscription/companySubscription'),
  loading,
});

function App(props) {
  const history = useHistory();

  useEffect(() => {
    document.title = process.env.REACT_APP_API_TITLE;
    props.setActiveClass('dashboard');
    return () => {
      if (socket) {
        socket.emit('disconnect');
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTheme = () => {
    // const res = await getSetting();
    // const data = res.data.data;
    // this.props.settheme({
    //   logo: data.logo,
    //   color: data.primaryColor,
    //   darkLogo: data.darkLogo,
    // });
  };

  useEffect(() => {
    if (
      !window.location.pathname.includes('/payment') &&
      !window.location.pathname.includes('/forget_password') &&
      !window.location.pathname.includes('/company-register') &&
      !window.location.pathname.includes('/company-subscription')
    ) {
      getData();
      connectSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.token]);

  const connectSocket = async () => {
    if (props.token !== null && props.company) {
      socket = await openSocket(process.env.REACT_APP_API_URL, {
        forceNew: true,
        transports: ['websocket'],
        query: {
          token: props.token,
          'x-tff-company-id': props.company.company_id,
        },
      });
    }
  };

  const getData = async () => {
    if (props.token == null) {
      return history.push('/login');
    }
    if (props.token !== null && props.user == null) {
      const res = await getUser();
      if (res.data.response_type === 'success') {
        props.setcurrentuser(res.data.data);
      } else {
        return history.push('/login');
      }
    }
  };

  return (
    <div className="">
      <Switch>
        <Route
          exact
          path="/company-register"
          name="Payment Page"
          component={CompanyRegister}
        />
        <Route
          exact
          path="/company-subscription/:id"
          name="Company Subscription Page"
          component={CompanySubscription}
        />
        <Route path="/login" name="Login" component={Login} />
        {/* <Route exact path="/forgot" name="Forgot Page" component={Forgot} />  */}
        <Route
          exact
          path="/payment/:token"
          name="Payment Page"
          component={DeliveryPayment}
        />
        <Route
          exact
          path="/forget_password/:token"
          name="Forget Password"
          component={Forgot}
        />

        {props &&
          props.token &&
          props.user &&
          props.company &&
          (props?.company?.role?.name === 'admin' ||
            props?.company?.role?.name === 'superAdmin') && (
            <Route path="/" name="Home" component={LayOut} />
          )}
        <Redirect from="*" exact={true} to="/login" />
      </Switch>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  token: selectToken,
  user: selectCurrentUser,
  company: selectCompany,
});
const mapDispatchToProps = (dispatch) => ({
  settheme: (theme) => dispatch(setTheme(theme)),
  setcurrentuser: (user) => dispatch(setCurrentUser(user)),
  setActiveClass: (activeClass) => dispatch(setActiveClass(activeClass)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
