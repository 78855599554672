import { axiosGet, axiosPatch, axiosPost } from '../axios/config';
import axios from 'axios';

export const login = (user) => {
  return axiosPost('/login', user);
};

export const emailCheck = (email) => {
  return axiosPost('/email-check', email);
};

export const getUser = () => {
  return axiosGet('/current-user');
};

export const getProfile = () => {
  return axiosGet('/admin/edit_profile');
};

export const updateProfile = (data) => {
  return axiosPatch('/admin/profile', data);
};

export const registerCompany = (user) => {
  return axiosPost('/register-company', user);
};

export const getCompanySubscriptionInfo = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/subscription`, {
    params: { id },
  });
};

export const createSubscription = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/subscription`, {
    ...data,
  });
};

export const getSubscription = () => {
  return axiosGet('/admin/subscription-details');
};

export const deleteSubscription = (data) => {
  return axiosGet('/admin/subscription-delete', data);
};

export const upgradeSubscription = (data) => {
  return axiosGet('/admin/subscription-upgrade', data);
};
