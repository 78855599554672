import TokenActionTypes from './token.type';

const INITIAL_STATE =null;

const tokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TokenActionTypes.SET_TOKEN:
      return action.payload;
    default:
      return state;
  }
}

export default tokenReducer;