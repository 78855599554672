import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user/user.reducer';
import tokenReducer from './token/token.reducer';
import errorNotificationReducer from './errorNotification/errorNotification.reducer';
import themeReducer from './theme/theme.reducer';
import activeClassReducer from './sidebarActive/sidebarActive.reducer';
import themeModeReducer from './themeMode/themeMode.reducer';
import companyReducer from './company/company.reducer';
import superAdminCompanyReducer from './superAdminCompany/superAdminCompany.reducer';

const persistConfig = {
  key: 'the_final_final',
  storage,
  // whitelist: ["theme_mode"],
};

const rootReducer = combineReducers({
  user: userReducer,
  token: tokenReducer,
  errorNotification: errorNotificationReducer,
  theme: themeReducer,
  sidebar_active_class: activeClassReducer,
  theme_mode: themeModeReducer,
  company: companyReducer,
  temp_company: superAdminCompanyReducer,
});

export default persistReducer(persistConfig, rootReducer);
