import SetActiveClassActionTypes from "./sidebarActive.type";

const INITIAL_STATE = "dashboard";

const activeClassReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SetActiveClassActionTypes.SET_ACTIVE_CLASS_SIDEBAR:
      return action.payload;
    default:
      return state;
  }
};

export default activeClassReducer;
