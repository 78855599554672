import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store.js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// eslint-disable-next-line no-extend-native
String.prototype.toCamelCase = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://d0eaa1a2a8e24f9a991e2b3f268f3958@o558500.ingest.sentry.io/5691983",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
