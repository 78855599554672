import axios from 'axios';
import { store } from '../redux/store';
import { setCurrentUser } from '../redux/user/user.action';
import { setToken } from '../redux/token/token.action';
import { setErrorNotification } from '../redux/errorNotification/errorNotification.action';
import moment from 'moment-timezone';

axios.interceptors.request.use(async (config) => {
  try {
    const state = store.getState();
    const authToken = state.token;
    config.headers[`x-tff-company-id`] =
      state?.company?.role?.name === 'superAdmin'
        ? state?.temp_company?.company_id
        : state?.company?.company_id;
    const utcTime = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    const time = moment().format('YYYY-MM-DD HH:mm:ss');
    const timeDiffInSecond = moment(time).diff(utcTime, 'second');

    if (authToken) {
      config.headers.Authorization = 'JWT ' + authToken;
      config.headers.timeVariationInSecond = timeDiffInSecond;
      config.headers.currentTime = time;
    }
    return config;
  } catch (err) {
    Promise.reject(err);
  }
});

axios.interceptors.response.use(
  (res) => {
    if (res.data.toast === true) {
      store.dispatch(
        setErrorNotification({
          message: res.data.message,
          toggle: res.data.toast,
          type: res.data.response_type,
        }),
      );
      setTimeout(() => {
        store.dispatch(
          setErrorNotification({
            message: '',
            toggle: false,
            type: '',
          }),
        );
      }, 5000);
    }
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      store.dispatch(setCurrentUser(null));
      store.dispatch(setToken(null));
      window.location.reload();
    }
  },
);

export default axios;

export const axiosPost = (url, data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosGet = (url, data) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    params: data,
  });
};

export const axiosDelete = (url) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}${url}`);
};

export const axiosPatch = (url, data) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
};
